import React from "react";
// @ts-ignore
import logoUrl from "../assets/images/logo.png";

type Props = {
    onLoaded?: () => void,
};

export const Logo: React.FC<Props> = (props) => {
    return (
        <div className={"logo"}>
            <img src={logoUrl} onLoad={props.onLoaded} alt=""/>
        </div>
    );
};