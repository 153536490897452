import React from "react";
import ReactDOM from "react-dom";
import { Container, Col, Row } from "react-bootstrap";
import { Logo } from "./components/Logo";

const MainApp: React.FC = () => {
    return (
        <Container fluid className="main">
            <Row className="m-2">
                <Col>
                    <Row>
                        <Logo />
                    </Row>
                    <Row className="txt-row">
                        cyberflow.AI
                    </Row>
                    <Row className="txt-row smaller">
                        coming soon
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

ReactDOM.render(
    <MainApp />,
    document.getElementById("root")
);
